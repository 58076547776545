import {useFormik} from 'formik';
import {
    Button,
    CustomPasswordField,
    CustomTextField,
    FormGroup,
    Label,
    PhoneNumberField,
} from '../..';
import {
    Box,
    Checkbox,
    FormControlLabel,
    FormHelperText,
    Grid,
    Theme,
    useMediaQuery,
} from '@material-ui/core';
import 'react-phone-number-input/style.css';
import useStyles from './RegisterFormStyles';
import registerSchema from '../../../validators/Register';
import PasswordStrengthBar from 'react-password-strength-bar';
import {Trans, useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../const/i18nNamespaces';
import {RegisterUserFormData} from '../../../types/Login';
import {
    PRIVACY_POLICY_PAGE,
    TERMS_AND_CONDITIONS_PAGE,
} from '../../../const/routes';
import {NewTabLink} from '../../common/navigation';
import {boolean} from 'yup';
import {useEffect, useState} from 'react';
import configurationProvider from '../../../services/configurationProvider';

type Props = {
    onSubmit: (values: RegisterUserFormData) => void;
    error: string;
    medicalServiceId?: number;
    isChildrenRequired?: boolean;
};

const RegisterForm = ({onSubmit, error, isChildrenRequired}: Props) => {
    const {t} = useTranslation(i18nNamespaces.AUTH);

    const formik = useFormik({
        initialValues: {
            name: '',
            surname: '',
            pesel: '',
            idNumber: '',
            email: '',
            password: '',
            repeatPassword: '',
            phoneNumber: '',
            streetName: '',
            houseNumber: '',
            apartmentNumber: '',
            postcode: '',
            cityName: '',
            childName: '',
            childSurname: '',
            childPesel: '',
            isChildrenRequired,
            acceptPolicy: false,
            acceptPrivacyPolicy: false,
            acceptReceivingEmails: false,
            acceptReceivingSMS: false,
        },
        validationSchema: registerSchema,
        onSubmit,
    });

    const {handleChange, handleBlur, handleSubmit, values, touched, errors} =
        formik;

    const isDesktop = useMediaQuery<Theme>(theme => theme.breakpoints.up('sm'));

    const classes = useStyles();

    const handlePhoneNumberChange = (value: string) =>
        handleChange({
            target: {
                value,
                id: 'phoneNumber',
                name: 'phoneNumber',
            },
        });

    return (
        <form onSubmit={handleSubmit}>
            <Box>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    <FormGroup className={classes.formGroup}>
                        <Label htmlFor="name">{t('name')}*</Label>
                        <CustomTextField
                            id="name"
                            name="name"
                            type="text"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.name}
                            helperText={touched.name && t(errors.name)}
                            error={touched.name && !!errors.name}
                        />
                    </FormGroup>
                    <FormGroup className={classes.formGroup}>
                        <Label htmlFor="surname">{t('surname')}*</Label>
                        <CustomTextField
                            id="surname"
                            name="surname"
                            type="text"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.surname}
                            helperText={touched.surname && t(errors.surname)}
                            error={touched.surname && !!errors.surname}
                        />
                    </FormGroup>
                    <FormGroup className={classes.formGroup}>
                        <Label htmlFor="pesel">{t('peselNumber')}*</Label>
                        <CustomTextField
                            id="pesel"
                            name="pesel"
                            type="text"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.pesel}
                            helperText={touched.pesel && t(errors.pesel)}
                            error={touched.pesel && !!errors.pesel}
                        />
                    </FormGroup>
                </Grid>
                {isChildrenRequired && (
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >
                        <FormGroup className={classes.formGroup}>
                            <Label htmlFor="childName">{t('childName')}*</Label>
                            <CustomTextField
                                id="childName"
                                name="childName"
                                type="text"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.childName}
                                helperText={
                                    touched.childName && t(errors.childName)
                                }
                                error={touched.childName && !!errors.childName}
                            />
                        </FormGroup>
                        <FormGroup className={classes.formGroup}>
                            <Label htmlFor="childSurname">
                                {t('childSurname')}*
                            </Label>
                            <CustomTextField
                                id="childSurname"
                                name="childSurname"
                                type="text"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.childSurname}
                                helperText={
                                    touched.childSurname &&
                                    t(errors.childSurname)
                                }
                                error={
                                    touched.childSurname &&
                                    !!errors.childSurname
                                }
                            />
                        </FormGroup>
                        <FormGroup className={classes.formGroup}>
                            <Label htmlFor="childPesel">
                                {t('childPeselNumber')}*
                            </Label>
                            <CustomTextField
                                id="childPesel"
                                name="childPesel"
                                type="text"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.childPesel}
                                helperText={
                                    touched.childPesel && t(errors.childPesel)
                                }
                                error={
                                    touched.childPesel && !!errors.childPesel
                                }
                            />
                        </FormGroup>
                    </Grid>
                )}

                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    <FormGroup className={classes.formGroup}>
                        <Label htmlFor="email">E-mail*</Label>
                        <CustomTextField
                            id="email"
                            name="email"
                            type="text"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                            helperText={touched.email && t(errors.email)}
                            error={touched.email && !!errors.email}
                        />
                    </FormGroup>
                    <FormGroup className={classes.formGroup}>
                        <Label htmlFor="phoneNumber">{t('phoneNumber')}*</Label>
                        <PhoneNumberField
                            id="phoneNumber"
                            name="phoneNumber"
                            onChange={handlePhoneNumberChange}
                            onBlur={handleBlur}
                            value={values.phoneNumber}
                            helperText={
                                touched.phoneNumber && t(errors.phoneNumber)
                            }
                            error={touched.phoneNumber && !!errors.phoneNumber}
                            defaultCountry="PL"
                            fullWidth={!isDesktop}
                        />
                    </FormGroup>
                </Grid>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    <FormGroup className={classes.formGroup}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            className={classes.passwordLabelContainer}
                        >
                            <Box>
                                <Label htmlFor="password">
                                    {t('passwordWithMinChars')}*
                                </Label>
                            </Box>
                            <Box
                                className={classes.passwordStrengthBarContainer}
                            >
                                <PasswordStrengthBar
                                    password={values.password}
                                    scoreWords={[]}
                                    scoreWordStyle={{display: 'none'}}
                                />
                            </Box>
                        </Grid>
                        <CustomPasswordField
                            id="password"
                            name="password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password}
                            helperText={touched.password && t(errors.password)}
                            error={touched.password && !!errors.password}
                        />
                    </FormGroup>
                    <FormGroup className={classes.formGroup}>
                        <Label htmlFor="repeatPassword">
                            {t('repeatPassword')}*
                        </Label>
                        <CustomPasswordField
                            id="repeatPassword"
                            name="repeatPassword"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.repeatPassword}
                            helperText={
                                touched.repeatPassword &&
                                t(errors.repeatPassword)
                            }
                            error={
                                touched.repeatPassword &&
                                !!errors.repeatPassword
                            }
                        />
                    </FormGroup>
                </Grid>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    className={classes.formSubsection}
                >
                    <FormGroup className={classes.formGroup}>
                        <Label htmlFor="streetName">{t('street')}*</Label>
                        <CustomTextField
                            id="streetName"
                            name="streetName"
                            type="text"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.streetName}
                            helperText={
                                touched.streetName && t(errors.streetName)
                            }
                            error={touched.streetName && !!errors.streetName}
                        />
                    </FormGroup>
                    <FormGroup className={classes.formGroup}>
                        <Label htmlFor="houseNumber">{t('houseNumber')}*</Label>
                        <CustomTextField
                            id="houseNumber"
                            name="houseNumber"
                            type="text"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.houseNumber}
                            helperText={
                                touched.houseNumber && t(errors.houseNumber)
                            }
                            error={touched.houseNumber && !!errors.houseNumber}
                            className={classes.numberInput}
                        />
                    </FormGroup>
                    <FormGroup className={classes.formGroup}>
                        <Label htmlFor="apartmentNumber">
                            {t('apartmentNumber')}
                        </Label>
                        <CustomTextField
                            id="apartmentNumber"
                            name="apartmentNumber"
                            type="text"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.apartmentNumber}
                            helperText={
                                touched.apartmentNumber &&
                                t(errors.apartmentNumber)
                            }
                            error={
                                touched.apartmentNumber &&
                                !!errors.apartmentNumber
                            }
                            className={classes.apartmentInput}
                        />
                    </FormGroup>
                </Grid>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    <FormGroup className={classes.formGroup}>
                        <Label htmlFor="postcode">{t('postcode')}*</Label>
                        <CustomTextField
                            id="postcode"
                            name="postcode"
                            type="text"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.postcode}
                            helperText={touched.postcode && t(errors.postcode)}
                            error={touched.postcode && !!errors.postcode}
                            className={classes.codeInput}
                        />
                    </FormGroup>
                    <FormGroup className={classes.formGroup}>
                        <Label htmlFor="cityName">{t('cityName')}*</Label>
                        <CustomTextField
                            id="cityName"
                            name="cityName"
                            type="text"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.cityName}
                            helperText={touched.cityName && t(errors.cityName)}
                            error={touched.cityName && !!errors.cityName}
                        />
                    </FormGroup>
                </Grid>
                <Grid
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    <Grid item className={classes.formSubsection}>
                        * {t('requiredAddress')}
                    </Grid>
                    <Grid item className={classes.formSubsection}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    id="acceptPolicy"
                                    name="acceptPolicy"
                                    checked={values.acceptPolicy}
                                    onChange={handleChange}
                                />
                            }
                            label={
                                <Trans
                                    t={t}
                                    i18nKey="acceptPolicy"
                                    components={[
                                        <NewTabLink
                                            key={'0'}
                                            href={TERMS_AND_CONDITIONS_PAGE}
                                            className={
                                                classes.termsAndConditionLink
                                            }
                                        ></NewTabLink>,
                                    ]}
                                />
                            }
                        />
                        <FormHelperText
                            error={
                                touched.acceptPolicy && !!errors.acceptPolicy
                            }
                        >
                            {touched.acceptPolicy && t(errors.acceptPolicy)}
                        </FormHelperText>
                    </Grid>

                    {!!error && (
                        <Grid item className={classes.formSubsection}>
                            <Box color="red" fontSize={16}>
                                {error}
                            </Box>
                        </Grid>
                    )}
                </Grid>
                <Grid
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    <Grid item className={classes.formSubsection}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    id="acceptPrivacyPolicy"
                                    name="acceptPrivacyPolicy"
                                    checked={values.acceptPrivacyPolicy}
                                    onChange={handleChange}
                                />
                            }
                            label={
                                <Trans
                                    t={t}
                                    i18nKey="acceptPrivacyPolicy"
                                    components={[
                                        <NewTabLink
                                            key={'0'}
                                            href={PRIVACY_POLICY_PAGE}
                                            className={
                                                classes.termsAndConditionLink
                                            }
                                        ></NewTabLink>,
                                    ]}
                                />
                            }
                        />
                        <FormHelperText
                            error={
                                touched.acceptPrivacyPolicy &&
                                !!errors.acceptPrivacyPolicy
                            }
                        >
                            {touched.acceptPrivacyPolicy &&
                                t(errors.acceptPrivacyPolicy)}
                        </FormHelperText>
                    </Grid>

                    {!!error && (
                        <Grid item className={classes.formSubsection}>
                            <Box color="red" fontSize={16}>
                                {error}
                            </Box>
                        </Grid>
                    )}
                </Grid>

                <Grid
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    <Grid item className={classes.formSubsection}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    id="acceptReceivingEmails"
                                    name="acceptReceivingEmails"
                                    checked={values.acceptReceivingEmails}
                                    onChange={handleChange}
                                />
                            }
                            label={
                                <Trans t={t} i18nKey="acceptReceivingEmails" />
                            }
                        />
                    </Grid>
                </Grid>
                <Grid
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    <Grid item className={classes.formSubsection}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    id="acceptReceivingSMS"
                                    name="acceptReceivingSMS"
                                    checked={values.acceptReceivingSMS}
                                    onChange={handleChange}
                                />
                            }
                            label={<Trans t={t} i18nKey="acceptReceivingSMS" />}
                        />
                    </Grid>
                </Grid>

                <Grid
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    <Grid item className={classes.submitContainer}>
                        <Button
                            variant="contained"
                            color="secondary"
                            type="submit"
                            className={classes.submitButton}
                        >
                            {t('register')}
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </form>
    );
};

export default RegisterForm;
