import useStyles from './AppointmentsCalendarPageStyles';
import React, {useContext, useEffect, useState} from 'react';
import {
    FinishedAppointments,
    Loading,
    PatientCalendarReferrals,
    PatientReferralsForMedicalTests,
    UpcomingAppointments,
} from '../../components';
import {Box, Grid} from '@material-ui/core';
import {useAppDispatch, useAppSelector} from '../../hooks/customReduxHooks';
import {selectPatientReferralsStatus} from '../../store/patientCalendar/referralsToSpecialistSlice';
import {selectUpcomingAppointmentsStatus} from '../../store/patientCalendar/upcomingAppointmentsSlice';
import {selectFinishedAppointmentsStatus} from '../../store/patientCalendar/finishedAppointmentsSlice';
import {ReferralsForMedicalTestsContext} from '../../hooks/referrals/patientMedicalTests/useReferralsForMedicalTestsContext';
import usePatientReferralsForMedicalTests from '../../hooks/referrals/patientMedicalTests/useReferralsForMedicalTests';
import {useHistory} from 'react-router';
import useQuery from '../../hooks/useQuery';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../const/i18nNamespaces';
import PatientAddressComponent from '../../components/account/PatientAddressComponent/PatientAddressComponent';
import PatientInsuranceNFZDeclaration from '../../components/account/PatientInsuranceNFZDeclaration/PatientInsuranceNFZDeclaration';
import {
    fetchPatientPersonalData,
    fetchPatientNFZInsuranceDeclaration,
    selectHasAddress,
    selectHasNFZInsuranceDeclaration,
    selectIsIdentityConfirmed,
    selectPatientPersonalStatus,
    selectHasNFZInsuranceDeclarationStatus,
} from '../../store/patientCalendar/patientPersonalDataStatusSlice';
import accountProvider from '../../services/accountProvider';
import {isNotNullOrUndefined} from '../../utils/lang';
import {insuranceDeclarationApi} from '../../api/insuranceDeclaration/insuranceDeclarationApi';
import {selectAuthUserData} from '../../store/auth';
import {PatientInsuranceDeclarationDto} from '../../types/insuranceDeclaration';
import PatientConfirmIdentityComponent from '../../components/account/PatientConfirmIdentityComponent/PatientConfirmIdentityComponent';
import {ConfirmIdentitySimplyDto} from '../../types/Login';
import {postFormToLocation} from '../../utils/postFormToLocation';
import PatientOccupationalMedicineReferrals from '../../components/appointmentsCalendar/PatientOccupationalMedicineReferrals/PatientOccupationalMedicineReferrals';
import {AlertContext} from '../../contexts/AlertContext';

const ADDRESS = 'address';
const INSURANCE_NFZ_DECLARATION = 'insuranceNFZDeclaration';
const CONFIRM_IDENTITY = 'confirmIdentity';

interface Components {
    name: string;
    componentToRender: React.ReactNode;
}

const AppointmentsCalendarPage = () => {
    const classes = useStyles();

    const query = useQuery();
    const history = useHistory();
    const {t} = useTranslation(i18nNamespaces.COMMON);
    const {t: accountT} = useTranslation(i18nNamespaces.ACCOUNT);
    const {t: errorT} = useTranslation(i18nNamespaces.ERRORS);
    const [error, setError] = useState<string>();

    const [loading, setLoading] = useState<boolean>(false);
    const dispatch = useAppDispatch();

    const authUserData = useAppSelector(selectAuthUserData);

    const addAddress = async (values: {
        streetName: string;
        houseNumber: string;
        apartmentNumber: string;
        postcode: string;
        cityName: string;
    }) => {
        try {
            setLoading(true);
            await accountProvider.updateAddressData(values);
            dispatch(fetchPatientPersonalData({fetchSilently: false}));
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const addDeclaration = async (values: PatientInsuranceDeclarationDto) => {
        try {
            setLoading(true);
            const {data} =
                await insuranceDeclarationApi.sendInsuranceDeclaration(
                    values,
                    authUserData.userId,
                );
            dispatch(
                fetchPatientNFZInsuranceDeclaration({fetchSilently: false}),
            );
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    const confirmIdentity = async (data: ConfirmIdentitySimplyDto) => {
        try {
            setLoading(true);
            await accountProvider.confirmIdentity(data);
            dispatch(fetchPatientPersonalData({fetchSilently: false}));
        } catch (err) {
            setError(accountT('errorOccuredCheckIfDataIsCorrect'));
        } finally {
            setLoading(false);
        }
    };

    const confirmWithMojeID = async () => {
        const {url, parameterName, parameterValue} =
            await accountProvider.getIdentityConfirmationRequestData();

        const formData = new Map<string, string>();
        formData.set(parameterName, parameterValue);

        postFormToLocation(url, formData);
    };

    const initlialComponents = [
        {
            name: ADDRESS,
            componentToRender: (
                <PatientAddressComponent onSubmit={addAddress} />
            ),
        },
        {
            name: INSURANCE_NFZ_DECLARATION,
            componentToRender: (
                <PatientInsuranceNFZDeclaration onSubmit={addDeclaration} />
            ),
        },
        {
            name: CONFIRM_IDENTITY,
            componentToRender: (
                <PatientConfirmIdentityComponent
                    onSubmit={confirmIdentity}
                    onConfirmWithMojeID={confirmWithMojeID}
                    error={error}
                />
            ),
        },
    ];

    const [componentsToRender, setComponentsToRender] = useState<Components[]>(
        [],
    );

    const patientReferralsState = useAppSelector(selectPatientReferralsStatus);

    const referralsForMedicalTestsState = usePatientReferralsForMedicalTests();
    const referralsForMedicalTestsLoading =
        referralsForMedicalTestsState.loading;
    const fetchReferralsForMedicalTests =
        referralsForMedicalTestsState.fetchReferrals;

    const personalDataState = useAppSelector(selectPatientPersonalStatus);

    const insuranceNFZDeclarationState = useAppSelector(
        selectHasNFZInsuranceDeclarationStatus,
    );

    const upcomingAppointmentsState = useAppSelector(
        selectUpcomingAppointmentsStatus,
    );
    const finishedAppointmentsState = useAppSelector(
        selectFinishedAppointmentsStatus,
    );

    const hasAddress = useAppSelector(selectHasAddress);

    const hasNFZInsuranceDeclaration = useAppSelector(
        selectHasNFZInsuranceDeclaration,
    );

    const isIdentityConfirmed = useAppSelector(selectIsIdentityConfirmed);
    const openAlert = useContext(AlertContext);

    useEffect(() => {
        let components = componentsToRender;
        components = [];

        if (!hasAddress && isNotNullOrUndefined(hasAddress)) {
            components.push(initlialComponents.find(x => x.name == ADDRESS));
        }
        // if (
        //     !hasNFZInsuranceDeclaration &&
        //     isNotNullOrUndefined(hasNFZInsuranceDeclaration)
        // ) {
        //     components.push(
        //         initlialComponents.find(
        //             x => x.name == INSURANCE_NFZ_DECLARATION,
        //         ),
        //     );
        // }
        if (!isIdentityConfirmed && isNotNullOrUndefined(isIdentityConfirmed)) {
            components.push(
                initlialComponents.find(x => x.name == CONFIRM_IDENTITY),
            );
        }

        setComponentsToRender(components);
    }, [hasNFZInsuranceDeclaration, hasAddress, isIdentityConfirmed, error]);

    useEffect(() => {
        dispatch(fetchPatientPersonalData({fetchSilently: false}));
        dispatch(fetchPatientNFZInsuranceDeclaration({fetchSilently: false}));
    }, [dispatch]);

    useEffect(() => {
        fetchReferralsForMedicalTests();
    }, [fetchReferralsForMedicalTests]);

    const loadingData =
        patientReferralsState === 'loading' ||
        referralsForMedicalTestsLoading ||
        upcomingAppointmentsState === 'loading' ||
        finishedAppointmentsState === 'loading' ||
        insuranceNFZDeclarationState === 'loading' ||
        personalDataState === 'loading';

    useEffect(() => {
        if (query.has('error')) {
            const errorMessage = query.get('error');
            openAlert({
                severity: 'error',
                message: t(errorMessage, errorT('unexpectedErrorOccurred')),
            });
        }
    }, []);

    const clearErrorMessage = () => {
        query.delete('error');
        history.replace({search: query.toString()});
        setError('');
    };

    return (
        <>
            <Loading withBackdrop={true} loading={loadingData || loading} />
            <Box className={classes.container}>
                <Grid container spacing={1} className={classes.topContainer}>
                    {componentsToRender &&
                        componentsToRender.map((item, i) => (
                            <Grid item key={i} lg={6} xs={12}>
                                {item.componentToRender}
                            </Grid>
                        ))}
                </Grid>
                <PatientOccupationalMedicineReferrals />
                <PatientCalendarReferrals />
                <ReferralsForMedicalTestsContext.Provider
                    value={referralsForMedicalTestsState}
                >
                    <PatientReferralsForMedicalTests />
                </ReferralsForMedicalTestsContext.Provider>
                <UpcomingAppointments />
                <FinishedAppointments />
            </Box>
        </>
    );
};

export default AppointmentsCalendarPage;
