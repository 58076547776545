import React, {FormEvent, ReactEventHandler, useContext, useRef, useState} from 'react';
import {Box, Button, Card, Grid, Theme, useMediaQuery, useTheme} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../const/i18nNamespaces';
import FormikTextField from '../../common/form/FormikTextField/FormikTextField';
import {useFormik} from 'formik';
import * as yup from 'yup';
import {individualPackageApi} from '../../../api/individualPackage/individualPackageApi';
import {AlertContext} from '../../../contexts/AlertContext';
import axios from 'axios';
import {QbCardOwner} from '../../../types/individualPackages';
import configuration from '../../../configuration';
import {Loading} from '../../index';
import {NewTabLink} from '../../common/navigation';
import FormMessage from '../../common/form/FormMessage/FormMessage';
import {desktop, mobile} from '../../../const/sizes';
import ResellerCode from "../../subscriptionPayment/ResellerCode/ResellerCode";
import subscriptionsProvider from "../../../services/subscriptionsProvider";
import {ResellerCodeValidationDto} from "../../../types/subscriptions";

interface FormElements extends HTMLFormControlsCollection {
    code: HTMLInputElement;
}

type ResellerCodeCardProps = {
    onSuccess: ({code, data}: {code: string, data: ResellerCodeValidationDto}) => void;
    individualPackageDraftId: number;
};
const ResellerCodeCard = ({onSuccess, individualPackageDraftId}: ResellerCodeCardProps) => {
    const {t} = useTranslation(i18nNamespaces.INDIVIDUAL_PACKAGES);
    const [loading, setLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const {palette} = useTheme();
    const codeRef = useRef<HTMLInputElement>();

    const handleUseResellerCode = async (code: string) => {
        try{
            setErrorMessage(null);
            setLoading(true);
            const {data} = await subscriptionsProvider.validateResellerCode(
                individualPackageDraftId,
                code,
            );
            onSuccess({code, data});
        } catch(error){
            console.error(error);
            if (axios.isAxiosError(error)) {
                setErrorMessage(error.response.data);
            }
        } finally {
            setLoading(false);
        }
    }

    const handleSubmitForm = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const elements = event.currentTarget.elements as FormElements;
        handleUseResellerCode(elements.code?.value);
    }

    return (
        <form onSubmit={handleSubmitForm}>
            <Loading loading={loading} withBackdrop />
            <Card elevation={0} style={{padding: 16, marginBottom: 16}}>
                <Box
                    style={{
                        fontWeight: 'bold',
                        marginBottom: 8,
                    }}
                >
                    {t('packageAvailableOnlyForResellerCodeOwners')}
                </Box>
                <Box >
                    {t('youDontHaveACode')}{' '}
                    <NewTabLink
                        color={palette.secondary.main}
                        href={configuration?.findUnilinkAgentUrl}
                    >
                        {t('findUnilinkAgent')}
                    </NewTabLink>
                </Box>
                <Box style={{
                    marginTop: 16,
                    marginBottom: 8
                }}>{t('agentCode')}:</Box>
                <ResellerCode
                    onUseResellerCode={handleUseResellerCode}
                    loading={loading}
                    errorText={errorMessage}
                    style={{marginBottom: 8}}
                    inputRef={codeRef}
                />
            </Card>
        </form>
    );
};

export default ResellerCodeCard;
