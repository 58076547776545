import { Box, FormGroup, Grid, TextField, Theme, useMediaQuery } from "@material-ui/core";
import AddPersonToPackageForm from "../forms/AddPersonToPackageForm/AddPersonToPackageForm";
import { useTranslation } from "react-i18next";
import i18nNamespaces from "../../const/i18nNamespaces";
import useStyles from "./AddChildCardStyles";
import Label from "../common/Label/Label";
import CustomTextField from "../common/CustomTextField/CustomTextField";
import { useFormik } from "formik";
import addAdultToPackageSchema from "../../validators/addAdultToPackage";
import { MemberDto } from "../../types/patientPackages";
import CustomButton from "../common/Button/Button";


type AddChildCardProps = {
    onSubmit: (data: MemberDto) => void;
    error?: string;
    isLoading:boolean;
};

const AddChildCard = ({
    onSubmit,
    error,
    isLoading
}: AddChildCardProps) => {
    const {t: commonT} = useTranslation(i18nNamespaces.COMMON);
    const {t: authT} = useTranslation(i18nNamespaces.AUTH);
    const classes = useStyles();
    const isDesktop = useMediaQuery<Theme>(theme => theme.breakpoints.up('sm'));
    
    const {values, touched, handleChange, handleBlur, errors, handleSubmit} =
        useFormik({
            initialValues: {
                name: '',
                surname: '',
                pesel: '',
            },
            validationSchema: addAdultToPackageSchema,
            onSubmit,
        });
        
    return (
        <form className={classes.container} onSubmit={handleSubmit}>
            <Box className={classes.childrenTileHeader}>
                {commonT('addChildData')}
            </Box>
            <Grid container spacing={1}>
                <Grid item xs={12} md={4}>
                    <FormGroup className={classes.input}>
                        <Label htmlFor="name">{authT('name')}</Label>
                        <CustomTextField 
                            fullWidth 
                            id="name"
                            name="name"
                            type="text"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.name}
                            helperText={touched.name && authT(errors.name)}
                            error={touched.name && !!errors.name}/>
                    </FormGroup>
                </Grid>
                <Grid item xs={12} md={4}>
                    <FormGroup className={classes.input}>
                        <Label htmlFor="surname">{authT('surname')}</Label>
                        <CustomTextField 
                            fullWidth
                            id="surname"
                            name="surname"
                            type="text"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.surname}
                            helperText={touched.surname && authT(errors.surname)}
                            error={touched.surname && !!errors.surname}/>
                    </FormGroup >
                </Grid>
                <Grid item xs={12} md={4}>
                    <FormGroup className={classes.input}> 
                        <Label htmlFor="pesel">{authT('peselNumber')}</Label>
                        <CustomTextField 
                            fullWidth
                            id="pesel"
                            name="pesel"
                            type="text"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.pesel}
                            helperText={touched.pesel && authT(errors.pesel)}
                            error={touched.pesel && !!errors.pesel} />
                    </FormGroup>
                </Grid>
            </Grid>
            {error && (
                <Grid item className={classes.error}>
                    {error}
                </Grid>
            )}
            <Grid item container className={classes.buttonRow}>
                <FormGroup className={classes.addButton}>
                    <CustomButton
                        type="submit"
                        variant="contained"
                        color="secondary"
                        disabled={isLoading}
                    >
                        {commonT('add')}
                    </CustomButton>
                </FormGroup>
            </Grid>
        </form>
)};

export default AddChildCard;
