import {
    Appointment,
    AppointmentConsultationType,
    AppointmentPaymentStatus,
    AppointmentStateEnum,
} from '../types/appointments';
import {
    CALL_PAGE,
    CHAT_PAGE,
    MEDIC_APPOINTMENT_DETAILS_PAGE,
    PATIENT_APPOINTMENT_DETAILS_PAGE,
    VIDEO_PAGE,
} from '../const/routes';
import {Chat, Phone, VoiceChat} from '@material-ui/icons';
import {TFunction} from 'react-i18next';
import {capitalizeFirstLetter} from './string';
import {MemberDto} from '../types/patientPackages';

const appointmentRoutes = {
    Chat: CHAT_PAGE,
    Phone: CALL_PAGE,
    Video: VIDEO_PAGE,
};

export const getMedicFullName = (medic: {
    title?: string;
    firstName: string;
    surname: string;
}) => `${medic.title} ${medic.firstName} ${medic.surname}`;

export const getPatientFullName = (patient: {
    firstName: string;
    surname: string;
}) => `${patient.firstName} ${patient.surname}`;

export const getMemberFullName = (member: MemberDto) =>
    `${member.name} ${member.surname}`;

export const getAppointmentRoute = (
    appointmentType: AppointmentConsultationType,
    appointmentId: string,
) => `${appointmentRoutes[appointmentType]}/${appointmentId}`;

export const canCancelAppointmentWithoutRefund = (
    appointmentState: AppointmentStateEnum,
    canCancelWithRefund: boolean,
): boolean => {
    return (
        appointmentState === AppointmentStateEnum.Planned &&
        !canCancelWithRefund
    );
};

export const isAppointmentEndedOrCanceled = (
    appointmentState: AppointmentStateEnum,
) => {
    return (
        appointmentState === AppointmentStateEnum.Canceled ||
        appointmentState === AppointmentStateEnum.PatientHasNotConnected ||
        appointmentState === AppointmentStateEnum.MedicHasNotConnected ||
        appointmentState === AppointmentStateEnum.Completed
    );
};

export const appointmentStatusWaiting = (appointment: Appointment) =>
    appointment.state.state === AppointmentStateEnum.InProgress &&
    !appointment.medic.connected &&
    !appointment.patient.connected;

export const appointmentStatusWaitingForPatient = (appointment: Appointment) =>
    appointment.state.state === AppointmentStateEnum.InProgress &&
    appointment.medic.connected &&
    !appointment.patient.connected;

export const appointmentStatusWaitingForMedic = (appointment: Appointment) =>
    appointment.state.state === AppointmentStateEnum.InProgress &&
    appointment.patient.connected &&
    !appointment.medic.connected;

export const appointmentStatusBothSidesConnected = (appointment: Appointment) =>
    appointment.state.state === AppointmentStateEnum.InProgress &&
    appointment.patient.connected &&
    appointment.medic.connected;

export const displayUnpaidLabel = (paymentStatus: AppointmentPaymentStatus) =>
    paymentStatus === 'Unpaid' ||
    paymentStatus === 'Canceled' ||
    paymentStatus === 'Pending';

export const getAppointmentTypes = (t: TFunction) => {
    const viaWord = capitalizeFirstLetter(t('via'));
    return [
        {
            value: 'Phone' as AppointmentConsultationType,
            label: `${viaWord} ${t('appointmentTypePhone')}`,
            icon: <Phone />,
        },
        {
            value: 'Chat' as AppointmentConsultationType,
            label: `${viaWord} ${t('appointmentTypeChat')}`,
            icon: <Chat />,
        },
        {
            value: 'Video' as AppointmentConsultationType,
            label: `${viaWord} ${t('appointmentTypeVideoChat')}`,
            icon: <VoiceChat />,
        },
    ];
};

export const getFilteredAppointmentTypes = (
    t: TFunction,
    videoChannel: boolean,
    chatChannel: boolean,
    callChannel: boolean,
) => {
    const viaWord = capitalizeFirstLetter(t('via'));
    const result = [];

    if (callChannel)
        result.push({
            value: 'Phone' as AppointmentConsultationType,
            label: `${viaWord} ${t('appointmentTypePhone')}`,
            icon: <Phone />,
        });
    if (chatChannel)
        result.push({
            value: 'Chat' as AppointmentConsultationType,
            label: `${viaWord} ${t('appointmentTypeChat')}`,
            icon: <Chat />,
        });
    if (videoChannel)
        result.push({
            value: 'Video' as AppointmentConsultationType,
            label: `${viaWord} ${t('appointmentTypeVideoChat')}`,
            icon: <VoiceChat />,
        });

    return result;
};

export const getAppointmentDetailsPageUrlById = (
    appointmentId: number | string,
    isMedic?: boolean,
) => {
    const detailsPage = isMedic
        ? MEDIC_APPOINTMENT_DETAILS_PAGE
        : PATIENT_APPOINTMENT_DETAILS_PAGE;
    return detailsPage + `/${appointmentId}`;
};

export const checkBothSidesConnectedToOngoingAppointment = (
    appointment: Appointment,
) => {
    const {
        state: {state},
        patient: {connected: patientConnected},
        medic: {connected: medicConnected},
    } = appointment;
    const bothSidesConnected = patientConnected && medicConnected;
    return state === AppointmentStateEnum.InProgress && bothSidesConnected;
};
