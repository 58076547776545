import {Box, Button, Card, Link} from '@material-ui/core';
import FormikTextField from '../../common/form/FormikTextField/FormikTextField';
import {useTranslation} from 'react-i18next';
import React, {useEffect, useMemo, useState} from 'react';
import i18nNamespaces from '../../../const/i18nNamespaces';
import {useFormik} from 'formik';
import * as yup from 'yup';
import {useAppDispatch, useAppSelector} from '../../../hooks/customReduxHooks';
import {clearError, selectAuthError, signIn} from '../../../store/auth';
import {Loading} from '../../index';
import FormMessage from '../../common/form/FormMessage/FormMessage';
import {signInErrorHandler} from '../../../utils/error';
import {desktop} from '../../../const/sizes';

const REQUIRED_STRING = 'requiredField';

type LoginCardProps = {
    onRegisterClick: () => void;
};
const LoginCard = ({onRegisterClick}: LoginCardProps) => {
    const {t} = useTranslation(i18nNamespaces.INDIVIDUAL_PACKAGES);
    const {t: authT} = useTranslation(i18nNamespaces.AUTH);
    const {t: commonT} = useTranslation(i18nNamespaces.COMMON);
    const [loading, setLoading] = useState<boolean>(false);
    const error = useAppSelector(selectAuthError);
    const errorMessage = useMemo(
        () => error && authT(signInErrorHandler(error)),
        [error],
    );
    const dispatch = useAppDispatch();

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: yup.object({
            email: yup
                .string()
                .email(authT('wrongEmail'))
                .required(commonT(REQUIRED_STRING)),
            password: yup
                .string()
                .min(6, commonT('minCharacters', {minChars: 6}))
                .required(commonT(REQUIRED_STRING)),
        }),
        onSubmit: async values => {
            try {
                setLoading(true);
                await dispatch(signIn(values));
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        },
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <Loading loading={loading} withBackdrop />
            <Card elevation={0} style={{padding: 16, marginBottom: 16}}>
                <Box
                    style={{
                        fontWeight: 'bold',
                        marginBottom: 8,
                    }}
                >
                    {t('toMakeAPurchaseLogIn')}
                </Box>
                <Box style={{marginBottom: 8}}>
                    {authT('youDontHaveAnAccountInGlobalTeleclinicYet')}{' '}
                    <Link
                        color={'secondary'}
                        onClick={onRegisterClick}
                        style={{cursor: 'pointer'}}
                    >
                        {authT('signUp')}
                    </Link>
                </Box>
                <FormikTextField
                    form={formik}
                    label={authT('emailAddress') + ' *'}
                    fieldName={'email'}
                    fullWidth
                />
                <FormikTextField
                    form={formik}
                    label={authT('password') + ' *'}
                    fieldName={'password'}
                    type={'password'}
                    fullWidth
                />
                <FormMessage
                    message={errorMessage}
                    mt={desktop.contentSmallerSpacing}
                />
                <Button
                    style={{
                        marginTop: desktop.contentSmallerSpacing,
                        fontWeight: 'bold',
                    }}
                    variant={'contained'}
                    color={'secondary'}
                    type={'submit'}
                    fullWidth
                >
                    {t('signInAndBuyPackage')}
                </Button>
            </Card>
        </form>
    );
};

export default LoginCard;
