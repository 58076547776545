import {Theme} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import { BACKGROUND_PRIMARY, WHITE } from '../../const/colors';


const useStyles = makeStyles((theme: Theme) => ({
    container: {
        backgroundColor: WHITE,
        width: '100%',
        padding: 30,
    },
    containerDesktop: {
        minHeight: `calc(100vh - ${theme.spacing(7)}px)`,
        flexGrow: 1,
    },
    childrenTileHeader:{
        fontSize: 20,
        fontWeight: 700,
    },
    input:{
        marginTop: 10,
        // [theme.breakpoints.up('lg')]: {
        //     width: 200,
        // },
    },
    buttonRow: {marginTop: 20},
    addButton:{width:'100%'},
    error: {
        marginTop: 10,
        color: 'red',
    },
    [theme.breakpoints.up('sm')]: {
        buttonRow: {marginTop: 24, justifyContent: 'flex-end'},
    },

}));

export default useStyles;
