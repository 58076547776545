import {
    Box,
    Button,
    Grid,
    makeStyles,
    Paper,
    Theme,
    Typography,
} from '@material-ui/core';
import {
    getDateDifferenceFromNowInDays,
    getRelativeDay,
    getDateTime,
} from '../../../utils/date';
import {useAppSelector} from '../../../hooks/customReduxHooks';
import {
    selectAppointmentPurchaseOptions,
    selectSelectedAppointment,
} from '../../../store/timeSlotsSlice';
import {selectAuthUserData} from '../../../store/auth';
import AppointmentPatientPurchaseOptions from '../AppointmentPatientPurchaseOptions/AppointmentPatientPurchaseOptions';
import AppointmentAnonymousPurchaseOptions from '../AppointmentAnonymousPurchaseOptions/AppointmentAnonymousPurchaseOptions';
import {desktop, mobile} from '../../../const/sizes';
import {AppointmentPurchaseType} from '../../../const/appointmentPurchaseType';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../const/i18nNamespaces';
import useAppointment from '../../../hooks/appointment/useAppointment';
import useQuery from '../../../hooks/useQuery';
import {DateTime} from 'luxon';
import {getLanguage} from '../../../utils/translation';
import i18n from '../../../i18n/config';

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        padding: mobile.contentLargerSpacing,
    },
    term: {
        fontSize: 20,
        fontWeight: 700,
    },
    [theme.breakpoints.up('sm')]: {
        container: {
            padding: 24,
        },
    },
}));

type AppointmentSettingsProps = {
    onSubmit?: (
        appointmentType: string,
        appointmentPurchaseType: AppointmentPurchaseType,
        addPatientToQueue: boolean,
        individualPackageDraftId?: number,
        patientGroupDraftId?: number,
    ) => void;
    selectedChildId: number;
    disabled:boolean
};

const AppointmentSettings = ({
    onSubmit,
    selectedChildId,
    disabled
}: AppointmentSettingsProps) => {
    const selectedAppointment = useAppSelector(selectSelectedAppointment);
    const purchaseOptions = useAppSelector(selectAppointmentPurchaseOptions);
    const authUserData = useAppSelector(selectAuthUserData);
    const appointmentId = parseInt(useQuery().get('appointmentId'));
    const {appointment: replacedAppointment} = useAppointment(appointmentId);
    const {t} = useTranslation(i18nNamespaces.COMMON);

    const language = getLanguage(i18n?.language);

    const classes = useStyles();

    const appointmentDate = (date: DateTime) => {
        if (language === 'EN')
            return getDateDifferenceFromNowInDays(date) < 2
                ? `${getRelativeDay(date)} ${getDateTime(date, 'hh:mm a')}`
                : getDateTime(date, 'hh:mm a');
        else
            return getDateDifferenceFromNowInDays(date) < 2
                ? `${getRelativeDay(date)} ${getDateTime(date, 'HH:mm')}`
                : getDateTime(date, 'HH:mm');
    };

    const handleSubmitAppointmentReplacement = () => {
        onSubmit(
            replacedAppointment.type,
            'AppointmentReplacement',
            false,
            null,
            null,
        );
    };

    const renderReplacementTile = () => (
        <>
            <Box mt={1}>
                {t('currentAppointmentDate')}:{' '}
                {replacedAppointment &&
                    appointmentDate(replacedAppointment?.startDate)}
            </Box>
            <Button
                variant={'contained'}
                color={'secondary'}
                onClick={handleSubmitAppointmentReplacement}
                style={{
                    marginTop: desktop.contentSmallerSpacing,
                    fontWeight: 'bold',
                }}
            >
                {t('changeForNewDate')}
            </Button>
        </>
    );

    return (
        <Paper elevation={0}>
            <Grid container direction="column" className={classes.container}>
                <Typography className={classes.term}>
                    {purchaseOptions.appointmentReplacementOption
                        ? t('newAppointmentDate')
                        : t('appointmentDate')}
                    :{' '}
                    {selectedAppointment &&
                        appointmentDate(selectedAppointment?.startDate)}
                </Typography>
                {authUserData ? (
                    purchaseOptions.appointmentReplacementOption ? (
                        renderReplacementTile()
                    ) : (
                        <AppointmentPatientPurchaseOptions
                            onSubmit={onSubmit}
                            selectedChildId={selectedChildId}
                            disabled={disabled}
                        />
                    )
                ) : (
                    <AppointmentAnonymousPurchaseOptions />
                )}
            </Grid>
        </Paper>
    );
};

export default AppointmentSettings;
