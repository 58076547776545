import {createStyles, makeStyles, Theme} from '@material-ui/core';
import {LIGHT_GRAY} from '../../../../const/colors';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        avatar: {
            width: theme.spacing(6),
            height: theme.spacing(6),
        },
        selectedAvatar: {
            border: '2px solid',
            borderColor: theme.palette.secondary.main,
        },
        name: {
            marginLeft: theme.spacing(2),
            [theme.breakpoints.down('sm')]: {
                marginLeft: 16,
            },
        },
        container: {
            cursor: 'pointer',
            borderBottom: `1px solid ${LIGHT_GRAY}`,
            [theme.breakpoints.up('md')]: {
                paddingLeft: theme.spacing(2),
                paddingRight: theme.spacing(2),
            },
        },
        listItem: {
            [theme.breakpoints.down('sm')]: {
                paddingBottom: 30,
            },
        },
        timeBox: {
            minWidth: 90,
            height: 45,
            borderRadius: 2,
            alignItems: 'center',
            display: 'flex',
            padding: '0 6px',
            flexDirection: 'column',
            justifyContent: 'center',
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginBottom: 24,
                width: '100%',
            },
        },
        ratingWrapper: {
            display: 'flex',
            alignItems: 'center',
        },
        ratingText: {
            fontSize: 14,
            color: theme.palette.text.secondary,
            marginLeft: 10,
        },
    }),
);

export default useStyles;
