export const ADMIN = 'Admin';
export const MEDIC = 'Medic';
export const PATIENT = 'Patient';
export const NOT_CONFIRMED_USER = 'NotConfirmedUser';
export const PHONE_NUMBER_CONFIRMATION = 'PhoneNumberConfirmation';
export const EMAIL_CONFIRMATION = 'EmailConfirmation';
export const FORCED_PASSWORD_CHANGE = 'ForcedPasswordChange';



export type Role = typeof ADMIN | typeof MEDIC | typeof PATIENT | typeof NOT_CONFIRMED_USER 
| typeof PHONE_NUMBER_CONFIRMATION | typeof EMAIL_CONFIRMATION | typeof FORCED_PASSWORD_CHANGE
