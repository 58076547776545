
import * as yup from 'yup';

const REQUIRED_STRING = 'requiredField';

export const changePhoneNumberSchema = yup.object().shape({
    phoneNumber: yup
        .string()
        .min(12, 'tooShortPhoneNumber')
        .required(REQUIRED_STRING),
});