import {Box, Card} from '@material-ui/core';
import React from 'react';

type InfoCardProps = {
    title: string;
    children: React.ReactNode | string;
};
const InfoCard = ({title, children}: InfoCardProps) => (
    <Card elevation={0} style={{padding: 16, marginBottom: 16}}>
        <Box
            style={{
                fontWeight: 'bold',
                marginBottom: 8,
            }}
        >
            {title}
        </Box>
        <Box>{children}</Box>
    </Card>
);

export default InfoCard;
