import {Box, Button, Card, Link, useTheme} from '@material-ui/core';
import FormikTextField from '../../common/form/FormikTextField/FormikTextField';
import FormikPhoneNumberField from '../../common/form/FormikPhoneNumberField/FormikPhoneNumberField';
import FormikPasswordField from '../../common/form/FormikPasswordField/FormikPasswordField';
import FormikCheckboxField from '../../common/form/FormikCheckboxField/FormikCheckboxField';
import {Trans, useTranslation} from 'react-i18next';
import {NewTabLink} from '../../common/navigation';
import {
    PRIVACY_POLICY_PAGE,
    TERMS_AND_CONDITIONS_PAGE,
} from '../../../const/routes';
import React, {useEffect, useState} from 'react';
import i18nNamespaces from '../../../const/i18nNamespaces';
import {useFormik} from 'formik';
import * as yup from 'yup';
import {PASSWORD_REGEX} from '../../../validators/Register';
import {useAppDispatch, useAppSelector} from '../../../hooks/customReduxHooks';
import {selectAuthError, signUpSimply} from '../../../store/auth';
import {Loading} from '../../index';
import FormMessage from '../../common/form/FormMessage/FormMessage';
import {registerErrorHandler} from '../../../utils/error';
import {desktop, mobile} from "../../../const/sizes";

const REQUIRED_STRING = 'requiredField';

type RegistrationCardProps = {
    onLoginClick: () => void;
    ownerEmail?: string;
};
const RegistrationCard = ({
    onLoginClick,
    ownerEmail,
}: RegistrationCardProps) => {
    const {t} = useTranslation(i18nNamespaces.INDIVIDUAL_PACKAGES);
    const {t: authT} = useTranslation(i18nNamespaces.AUTH);
    const {t: commonT} = useTranslation(i18nNamespaces.COMMON);
    const [loading, setLoading] = useState<boolean>(false);
    const errorMessage = useAppSelector(selectAuthError);
    const {palette} = useTheme();
    const dispatch = useAppDispatch();

    const formik = useFormik({
        initialValues: {
            email: ownerEmail || '',
            phoneNumber: '',
            password: '',
            acceptTerms: false,
            acceptReceivingEmailsAndSMS: false,
        },
        validationSchema: yup.object({
            email: yup
                .string()
                .email(authT('wrongEmail'))
                .required(commonT(REQUIRED_STRING)),
            phoneNumber: yup
                .string()
                .min(12, authT('tooShortPhoneNumber'))
                .required(commonT(REQUIRED_STRING)),
            password: yup
                .string()
                .min(6, commonT('minCharacters', {minChars: 6}))
                .required(commonT(REQUIRED_STRING))
                .matches(PASSWORD_REGEX, authT('passwordRules')),
            acceptTerms: yup.boolean().oneOf([true], commonT(REQUIRED_STRING)),
        }),
        onSubmit: async values => {
            try {
                setLoading(true);
                await dispatch(signUpSimply(values));
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        },
    });

    useEffect(() => {
        errorMessage === 'EmailTaken' &&
            formik.setFieldError('email', registerErrorHandler(errorMessage));
    }, [errorMessage]);

    return (
        <form onSubmit={formik.handleSubmit}>
            <Loading loading={loading} withBackdrop />
            <Card elevation={0} style={{padding: 16, marginBottom: 16}}>
                <Box
                    style={{
                        fontWeight: 'bold',
                        marginBottom: 8,
                    }}
                >
                    {t('toMakeAPurchaseCreateAnAccount')}
                </Box>
                <Box style={{marginBottom: 8}}>
                    {t('doYouAlreadyHaveAnAccountInGlobalTeleclinic')}{' '}
                    <Link
                        color={'secondary'}
                        onClick={onLoginClick}
                        style={{cursor: 'pointer'}}
                    >
                        {t('logIn')}
                    </Link>
                </Box>
                <FormikTextField
                    form={formik}
                    label={authT('emailAddress') + ' *'}
                    fieldName={'email'}
                    fullWidth
                />
                <FormikPhoneNumberField
                    form={formik}
                    label={authT('phoneNumber') + ' *'}
                    fieldName={'phoneNumber'}
                    fullWidth
                />
                <FormikPasswordField
                    form={formik}
                    label={authT('passwordWithMinChars') + ' *'}
                    fieldName={'password'}
                    showStrengthBar
                    fullWidth
                />
                <FormikCheckboxField
                    form={formik}
                    fieldName={'acceptTerms'}
                    error={formik.errors.acceptTerms}
                    label={
                        <>
                            <Trans
                                t={authT}
                                i18nKey="acceptTermsAndConditionsAndPrivacyPolicy"
                                components={[
                                    <NewTabLink
                                        key={'0'}
                                        href={TERMS_AND_CONDITIONS_PAGE}
                                        color={palette.secondary.main}
                                    />,
                                    <NewTabLink
                                        key={'1'}
                                        href={PRIVACY_POLICY_PAGE}
                                        color={palette.secondary.main}
                                    />,
                                ]}
                            />
                            {' *'}
                        </>
                    }
                />
                <FormikCheckboxField
                    form={formik}
                    fieldName={'acceptReceivingEmailsAndSMS'}
                    label={authT('acceptReceivingEmailsAndSMS')}
                />
                <FormMessage
                    mt={desktop.contentSmallerSpacing}
                    message={
                        errorMessage &&
                        errorMessage !== 'EmailTaken' &&
                        registerErrorHandler(errorMessage)
                    }
                />
                <Button
                    style={{marginTop: desktop.contentSmallerSpacing, fontWeight: 'bold'}}
                    variant={'contained'}
                    color={'secondary'}
                    type={'submit'}
                    fullWidth
                >
                    {t('createAnAccountAndBuyPackage')}
                </Button>
            </Card>
        </form>
    );
};

export default RegistrationCard;
