import {Box, Button, Card, Grid, Theme, useMediaQuery} from '@material-ui/core';
import FormikTextField from '../../common/form/FormikTextField/FormikTextField';
import {useTranslation} from 'react-i18next';
import {ERROR, SUBSCRIPTION_PAYMENT_CONTINUE} from '../../../const/routes';
import React, {FormEvent, useState} from 'react';
import i18nNamespaces from '../../../const/i18nNamespaces';
import {useFormik} from 'formik';
import * as yup from 'yup';
import {usePayUSecureForms} from '../../common/payUSecureForms';
import {Loading, SubscriptionPaymentCard} from '../../index';
import subscriptionsProvider from '../../../services/subscriptionsProvider';
import {generatePath, useHistory} from 'react-router';
import axios from 'axios';
import {QbCardOwner} from '../../../types/individualPackages';
import FormMessage from '../../common/form/FormMessage/FormMessage';
import {desktop} from "../../../const/sizes";

const REQUIRED_STRING = 'requiredField';

type PurchaseCardProps = {
    individualPackageDraftId: number;
    qbCardOwner?: QbCardOwner;
    resellerCode?: string;
};
const PurchaseCard = ({
    individualPackageDraftId,
    qbCardOwner,
    resellerCode
}: PurchaseCardProps) => {
    const {t} = useTranslation(i18nNamespaces.SUBSCRIPTION_PAYMENT);
    const {t: authT} = useTranslation(i18nNamespaces.AUTH);
    const {tokenize, sendCvv} = usePayUSecureForms();
    const history = useHistory();
    const isDesktop = useMediaQuery<Theme>(theme => theme.breakpoints.up('md'));
    const [loading, setLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [requiredCheckForced, setRequiredCheckForced] =
        useState<boolean>(false);

    /* TODO: Add gtm service to register payment */

    const formik = useFormik({
        initialValues: {
            name: qbCardOwner?.name || '',
            surname: qbCardOwner?.surname || '',
            streetName: '',
            houseNumber: '',
            apartmentNumber: '',
            postcode: '',
            cityName: '',
        },
        validationSchema: yup.object({
            name: yup.string().required(authT(REQUIRED_STRING)),
            surname: yup.string().required(authT(REQUIRED_STRING)),
            streetName: yup.string().required(authT(REQUIRED_STRING)),
            houseNumber: yup.string().required(authT(REQUIRED_STRING)),
            postcode: yup.string().required(authT(REQUIRED_STRING)),
            cityName: yup.string().required(authT(REQUIRED_STRING)),
        }),
        onSubmit: async values => {
            try {
                setLoading(true);
                // Tokenize the payment card.
                const tokenizationResult = await tokenize('MULTI');

                if (tokenizationResult.status !== 'SUCCESS') {
                    setLoading(false);
                    return;
                }

                const {
                    body: {token},
                } = tokenizationResult;

                // Create a subscription and send a payment order to PayU.

                const {subscriptionId, paymentContinuation} =
                    await subscriptionsProvider.createSubscription(
                        individualPackageDraftId,
                        {
                            buyerData: {
                                ...values,
                                type: 'IndividualPerson',
                                companyName: '',
                                nip: '',
                            },
                            cardToken: token,
                            qbCardNumber: qbCardOwner?.cardNumber,
                            resellerCode
                        },
                    );

                const paymentContinuePath = generatePath(
                    SUBSCRIPTION_PAYMENT_CONTINUE,
                    {subscriptionId},
                );

                // If there is no paymentContinuation, then the payment is finished.
                if (!paymentContinuation) {
                    history.push(paymentContinuePath);

                    setLoading(false);

                    return;
                }

                const {type, paymentUrl} = paymentContinuation;

                // Go to the payment URL in case an additional 3DS verification is required.
                if (type == 'CONTINUE_3DS') {
                    window.location.href = paymentUrl;
                }
                // Retrieve the card CVV code if it is required by PayU.
                else if (type == 'CONTINUE_CVV') {
                    const cvvResult = await sendCvv(paymentUrl);

                    if (cvvResult.status == 'SUCCESS') {
                        history.push(paymentContinuePath);
                    } else {
                        history.push(paymentUrl + '?error=CVV_FAILURE');
                    }
                }
                // Payment continuation with an unknown type. Go to the error page.
                else {
                    history.push(ERROR);
                }
            } catch (error) {
                setLoading(false);
                console.error(error);
                if (axios.isAxiosError(error))
                    setErrorMessage(t(error.response?.data[0]));
            }
        },
    });

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        setRequiredCheckForced(true);
        formik.handleSubmit();
    };

    return (
        <form onSubmit={handleSubmit}>
            <Loading loading={loading} withBackdrop />
            <Card
                elevation={0}
                style={{
                    padding: 16,
                    marginBottom: 16,
                }}
            >
                <Box
                    style={{
                        fontWeight: 'bold',
                        marginBottom: desktop.contentSmallerSpacing,
                    }}
                >
                    {t('enterYourBillingAddress')}
                </Box>
                <Grid container spacing={isDesktop ? 2 : 1}>
                    <Grid item sm={6} xs={12}>
                        <FormikTextField
                            form={formik}
                            label={authT('name') + ' *'}
                            fieldName={'name'}
                            noStyles
                            fullWidth
                        />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <FormikTextField
                            form={formik}
                            label={authT('surname') + ' *'}
                            fieldName={'surname'}
                            noStyles
                            fullWidth
                        />
                    </Grid>
                    <Grid item sm={12} lg={6} xs={12}>
                        <FormikTextField
                            form={formik}
                            label={authT('street') + ' *'}
                            fieldName={'streetName'}
                            noStyles
                            fullWidth
                        />
                    </Grid>
                    <Grid item sm={6} lg={3} xs={12}>
                        <FormikTextField
                            form={formik}
                            label={authT('houseNo') + ' *'}
                            fieldName={'houseNumber'}
                            noStyles
                            fullWidth
                        />
                    </Grid>
                    <Grid item sm={6} lg={3} xs={12}>
                        <FormikTextField
                            form={formik}
                            label={authT('apartmentNo')}
                            fieldName={'apartmentNumber'}
                            noStyles
                            fullWidth
                        />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <FormikTextField
                            form={formik}
                            label={authT('cityName') + ' *'}
                            fieldName={'cityName'}
                            noStyles
                            fullWidth
                        />
                    </Grid>
                    <Grid item sm={6} lg={3} xs={12}>
                        <FormikTextField
                            form={formik}
                            label={authT('postcode') + ' *'}
                            fieldName={'postcode'}
                            noStyles
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </Card>
            <Card
                elevation={0}
                style={{
                    padding: 16,
                    marginBottom: 16,
                }}
            >
                {individualPackageDraftId && (
                    <SubscriptionPaymentCard
                        header={
                            <Box style={{fontWeight: 'bold'}}>
                                {t('provideCreditCardDetails')}
                            </Box>
                        }
                        requiredCheckForced={requiredCheckForced}
                    />
                )}
            </Card>
            <Card
                elevation={0}
                style={{
                    padding: 16,
                    marginBottom: 16,
                }}
            >
                <FormMessage message={errorMessage} mb={1} />
                <Button
                    variant={'contained'}
                    color={'secondary'}
                    type={'submit'}
                    style={{fontWeight: 'bold'}}
                    fullWidth
                >
                    {t('pay')}
                </Button>
            </Card>
        </form>
    );
};

export default PurchaseCard;
