import * as yup from 'yup';
import {TFunction} from 'i18next';

export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W).{8,}$/;
const REQUIRED_STRING = 'requiredField';
const REQUIRED_CONSENT = 'requiredConsent';

const peselValidation = yup
    .string()
    .matches(/^[0-9]+$/, 'peselShouldContainOnlyNumbers')
    .length(11, 'lengthShouldBe11chars');

const registerSchema = yup.object().shape({
    name: yup.string().required(REQUIRED_STRING),
    surname: yup.string().required(REQUIRED_STRING),
    pesel: peselValidation.required(REQUIRED_STRING),
    childName: yup.string().when('isChildrenRequired', {
        is: true,
        then: yup.string().required(REQUIRED_STRING),
        otherwise: yup.string(),
    }),
    childSurname: yup.string().when('isChildrenRequired', {
        is: true,
        then: yup.string().required(REQUIRED_STRING),
        otherwise: yup.string(),
    }),
    childPesel: yup.string().when('isChildrenRequired', {
        is: true,
        then: peselValidation.required(REQUIRED_STRING),
        otherwise: peselValidation,
    }),
    email: yup.string().email('wrongEmail').required(REQUIRED_STRING),
    password: yup
        .string()
        .min(6, 'passwordMinCharsRules')
        .required(REQUIRED_STRING)
        .matches(PASSWORD_REGEX, 'passwordRules'),
    repeatPassword: yup
        .string()
        .oneOf([yup.ref('password'), null], 'passwordsMustBeTheSame')
        .required(REQUIRED_STRING),
    phoneNumber: yup
        .string()
        .min(12, 'tooShortPhoneNumber')
        .required(REQUIRED_STRING),
    streetName: yup.string().required(REQUIRED_STRING),
    houseNumber: yup.string().required(REQUIRED_STRING),
    postcode: yup.string().required(REQUIRED_STRING),
    cityName: yup.string().required(REQUIRED_STRING),
    acceptPolicy: yup.boolean().oneOf([true], REQUIRED_CONSENT),
    acceptPrivacyPolicy: yup.boolean().oneOf([true], REQUIRED_CONSENT),
});

export default registerSchema;
