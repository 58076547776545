import {FormHelperText, Input, InputBase, TextField} from '@material-ui/core';
import {forwardRef, InputHTMLAttributes, ReactNode, useRef} from 'react';
import PhoneInput, {Props} from 'react-phone-number-input';
import clsx from "clsx";

const PhoneNumberField = ({
    error,
    helperText,
    fullWidth,
    ...props
}: Props & {error?: boolean; helperText?: ReactNode; fullWidth?: boolean}) => {
    const phoneInputProps = props as Props;

    return (
        <>
            <PhoneInput
                {...phoneInputProps}
                error={error}
                defaultCountry="PL"
                style={{width: fullWidth ? '100%' : '300px'}}
                className={clsx({
                    'errorBorder': error
                })}
            />
            <FormHelperText
                variant="outlined"
                error={error}
                style={{width: fullWidth ? '100%' : '300px'}}
            >
                {helperText}
            </FormHelperText>
        </>
    );
};

export default PhoneNumberField;
