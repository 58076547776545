import {Theme} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import {BACKGROUND_PRIMARY} from '../../../const/colors';
import {desktop} from '../../../const/sizes';

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        backgroundColor: BACKGROUND_PRIMARY,
    },
    containerDesktop: {
        minHeight: `calc(100vh - ${theme.spacing(7)}px)`,
        flexGrow: 1,
        padding: 25,
        paddingRight: desktop.contentLargerSpacing,
    },
}));

export default useStyles;
